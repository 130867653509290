//swiper slider
var galleryThumbs = new Swiper(".gallery-thumbs", {
  spaceBetween: 10,
  slidesPerView: 5,
  freeMode: true,
  // loop: true,
  // loopedSlides: 5,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  breakpoints: {
    767: {
      slidesPerView: 4,
    },
    640: {
      slidesPerView: 3,
    },
  },
});

var galleryTop = new Swiper(".gallery-top", {
  spaceBetween: 10,
  loop: true,
  thumbs: {
    swiper: galleryThumbs,
  },
});

//validation form
$(document).ready(function () {
  $(":input").bind("keyup mouseup", function () {
    $(this).val($.trim($(this).val()));
  });
  $.validator.addMethod(
    "regexp",
    function (value, element) {
      return /^[a-zA-Zа-яА-Я]+$/iu.test(value);
    },
    "Use letters to enter the first and last name. Apostrophe, hyphen and space can be used"
  );
  $.validator.addMethod(
    "myemail",
    function (value, element) {
      return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      );
    },
    'Only Latin latters, numbers, dots, hyphen and "_" can be used.'
  );

  var submitEmailForm = $("#registration_form");

  submitEmailForm.validate({
    errorPlacement: function (error, element) {
      error.appendTo(element.parent("div").next("div"));
    },
    rules: {
      NAME: {
        regexp: true,
        minlength: 1,
        required: true,
      },
      LAST_NAME: {
        regexp: true,
        minlength: 1,
        required: true,
      },
      PERSONAL_COUNTRY: {
        required: true,
      },
      EMAIL: {
        required: true,
        myemail: true,
      },
      I_AGREE: {
        required: true,
      },
    },
    messages: {
      NAME: {
        required: "Fill this field",
      },
      LAST_NAME: {
        required: "Fill this field",
      },
      EMAIL: 'Only Latin letters, numbers, dots, hyphen and "_" can be used.',
      PERSONAL_COUNTRY: {
        required: "Fill this field",
      },
    },
  });
});

document.querySelectorAll(".mdc-text-field").forEach((__) => {
  mdc.textField.MDCTextField.attachTo(__);
});
document.querySelectorAll(".mdc-select").forEach((__) => {
  mdc.select.MDCSelect.attachTo(__);
});

//header scroll
$(window).scroll(function () {
  if ($(this).scrollTop() > 30) {
    $(".header").addClass("stick");
  } else {
    $(".header").removeClass("stick");
  }
});

$("#menu li a").click(function () {
  $("#menu li").removeClass("active");
  $(this).parent().addClass("active");
  return true;
});

$("#menu").on("click", "a", function (event) {
  event.preventDefault();
  var id = $(this)
    .attr("href")
    .substring($(this).attr("href").lastIndexOf("#"));
  var top = $(id).offset().top;
  $("body,html").animate({ scrollTop: top }, 700);
});

function createLinkParams(path, { r1, r2, r3, r4 }) {
  return (
    	path + "?" + Object.entries({ r1, r2, r3, r4 })
      .filter(([_, paramValue]) => paramValue != null)
      .map((pair) => pair.join("="))
      .join("&")
  );
}

function createLinkWithoutR3R4(path, lang, r1) {
  const hostname = window.location.hostname;
  if (hostname.indexOf("www.") === 0) {
    hostname = location.host.replace("www.", "");
  }
  if (!r1) {
    return path + "?r1=" + lang + "&r2=" + hostname;
  }
  if (r1) {
    return path + "?r1=" + r1 + "&r2=" + hostname;
  }
}

function createLink(langAtr, swither, anchorLink) {
  const hostname = window.location.origin;
  let publicDomain = swither
    ? hostname + "/" + langAtr
    : "https://account.weltrade.com/auth/registration/";
  let publicIdDomain = swither
    ? hostname + "/" + langAtr
    : "https://account.weltrade.business/auth/registration/";
  let publicCnDomain = swither
    ? hostname + "/" + langAtr
    : "https://account.weltrade.online/auth/registration/";
  if (anchorLink) {
    publicDomain = hostname;
    publicIdDomain = hostname;
    publicCnDomain = hostname;
  }

  const queryString = window.location.search;
  let locationPathname = window.location.pathname
    .replace(/\.html/, "")
    .replace("/", "");
  if (!locationPathname) {
    locationPathname = "en";
  }
  const urlParams = new URLSearchParams(queryString);

  const r1 = urlParams.get("r1"),
    r2 = urlParams.get("r2"),
    r3 = urlParams.get("r3"),
    r4 = urlParams.get("r4");

  if (!r1 && !r2 && !r3 && !r4) {
    if (locationPathname === "id") {
      return createLinkWithoutR3R4(publicIdDomain, locationPathname);
    } else if (locationPathname === "cn") {
      return createLinkWithoutR3R4(publicCnDomain, locationPathname);
    } else {
      return createLinkWithoutR3R4(publicDomain, locationPathname);
    }
  } else if (r1 && !r2 && !r3 && !r4) {
    if (locationPathname === "id") {
      return createLinkWithoutR3R4(publicIdDomain, locationPathname, r1);
    } else if (locationPathname === "cn") {
      return createLinkWithoutR3R4(publicCnDomain, locationPathname);
    } else {
      return createLinkWithoutR3R4(publicDomain, locationPathname, r1);
    }
  }

  if (locationPathname === "id") {
    return createLinkParams(publicIdDomain, { r1, r2, r3, r4 });
  } else if (locationPathname === "cn") {
    return createLinkParams(publicCnDomain, { r1, r2, r3, r4 });
  } else {
    return createLinkParams(publicDomain, { r1, r2, r3, r4 });
  }
}

function setLinkParams(elem, typeLink) {
  $(elem).each(function () {
    var $this = $(this);
    var _href = createLink();
    if (typeLink === "dropdown") {
      var _href = createLink($this.attr("href"), true);
    } else if (typeLink === "nav") {
      var _href = createLink(null, false, true) + "/" + $this.attr("href");
    }
    $this.attr("href", _href);
  });
}

$(document).ready(function () {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const r1 = urlParams.get("r1"),
  r2 = urlParams.get("r2");

  setLinkParams("a.linkparams");
  setLinkParams("a.logoparams");
  if (r1 && r2) {
    setLinkParams("div.dropdown-content > a", "dropdown");
  }
  setLinkParams("ul.nav > li > a", "nav");


  //date of birth company
  let companyLifeYear =  ((new Date().getTime() - new Date('2006-08-01')) / (24 * 3600 * 365.25 * 1000)) | 0;
  document.getElementById("companyLifeYear").textContent = companyLifeYear;

  //footer year
  let footerYear = new Date().getFullYear();
  document.getElementById("footerYear").textContent = footerYear;
});